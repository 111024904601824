import {Modal} from "../modal-module";
import {GarudaApi} from "../../infrastructure/garudaApi";
import {DropdownModule, NotificationModule} from "ditmer-embla";
import {CustomTooltipModule} from "../custom-tooltip-module";
import {Localization} from "../localization-module";

export class ReportMailSendModule {
    private modal: Modal;
    private tooltipModule: CustomTooltipModule
    private garudaApi: GarudaApi;
    private selectedEmails: string[] = [];
    private alertTimeout: number | null = null;

    private readonly maxEmails: number = 5;
    private readonly sendReportModalTriggerSelector: string = "#report-status-email-icon";
    private readonly sendReportModalSelector: string = "#report-mail-send-modal";
    private readonly emailLanguageSelectorSelector: string = "#email-language-selector";
    private readonly emailInputSelector: string = "#email-input";
    private readonly addEmailBtnSelector: string = "#add-email-btn";
    private readonly emailTagsContainerSelector: string = "#email-tags-container";
    private readonly outerCustomTooltipSelector?: string;

    constructor(garudaApi: GarudaApi) {
        this.garudaApi = garudaApi;
        this.outerCustomTooltipSelector = "#custom-tooltip-container";
    }

    public initModal(reportId: string, reportName: string): void {
        this.modal = new Modal(
            this.sendReportModalTriggerSelector,
            this.sendReportModalSelector,
            () => this.garudaApi.getReportMailModal(reportId),
            () => {
                this.initEvents();
                this.resetEmailState();
                this.initCustomTooltip("#custom-tooltip-container-mail-report");
            },
            () => this.applyModal(reportId, reportName)
        );

        this.modal.init();

        this.bindOnModalClose();
    }

    public async showModalContent(): Promise<void> {
        await this.modal.loadModalContent();
        this.modal.open();
    }

    private initCustomTooltip = (tooltipSelector: string) => {
        this.tooltipModule = new CustomTooltipModule({
            tooltipContainerIdSelector: tooltipSelector
        });
        this.tooltipModule.init();
    }

    private initEvents() {
        new DropdownModule().init(this.emailLanguageSelectorSelector, {
            allowClear: false,
            searchable: false,
            additionalSelect2Options: {
                tags: true
            }
        });

        this.updateAddButtonState();

        $(this.addEmailBtnSelector).off('click').on('click', () => this.addEmail());

        $(this.emailInputSelector).off('keydown').on('keydown', (e) => {
            if (e.key === 'Enter' && this.selectedEmails.length < this.maxEmails) {
                e.preventDefault();
                this.addEmail();
            }
        });

        $(this.emailTagsContainerSelector).off('click', '.report-email-btn-tag').on('click', '.report-email-btn-tag', (e) => {
            const email = $(e.currentTarget).data('email');
            this.removeEmail(email);
        });
    }

    private bindOnModalClose = () => {
        $(this.sendReportModalSelector).off('hidden.bs.modal').on('hidden.bs.modal', (e) => {
            $(e.currentTarget).find(".modal-body").empty();
            this.clearAlertTimeout();

            if(this.outerCustomTooltipSelector){
                this.initCustomTooltip(this.outerCustomTooltipSelector);
            }
        });
    }

    private updateAddButtonState() {
        const addButton = $(this.addEmailBtnSelector);
        const emailInput = $(this.emailInputSelector);

        if (this.selectedEmails.length >= this.maxEmails) {
            addButton.addClass("disabled-button");
            addButton.prop('disabled', true);
            emailInput.prop('disabled', true);
        } else {
            addButton.removeClass("disabled-button");
            addButton.prop('disabled', false);
            emailInput.prop('disabled', false);
        }
    }

    private resetEmailState(): void {
        this.selectedEmails = [];
        this.updateEmailTags();
        this.updateAddButtonState();
        $(this.emailInputSelector).val('');
    }

    private addEmail() {
        if (this.selectedEmails.length >= this.maxEmails) {
            this.showError(Localization.getText("ReportMailMaximum"));
            return;
        }

        const emailInput = $(this.emailInputSelector);
        const email = emailInput.val() as string;

        if (this.isValidEmail(email) && !this.selectedEmails.includes(email)) {
            this.selectedEmails.push(email);
            this.updateEmailTags();
            this.updateAddButtonState();
            emailInput.val('');
        } else if (!this.isValidEmail(email)) {
            this.showError(Localization.getText("Global_Email_Invalid"));
        } else {
            this.showError(Localization.getText("ReportMailDuplicate"));
        }
    }

    private removeEmail(email: string) {
        this.selectedEmails = this.selectedEmails.filter(e => e !== email);
        this.updateEmailTags();
        this.updateAddButtonState();
        if (this.selectedEmails.length === 0) {
            $(this.emailTagsContainerSelector).removeClass('margin-bottom-m');
        }
    }

    private updateEmailTags() {
        const container = $(this.emailTagsContainerSelector);
        container.empty();

        if (this.selectedEmails.length > 0) {
            container.addClass('margin-bottom-m');

            this.selectedEmails.forEach(email => {
                container.append(`
            <span class="report-email-tag">
                <button type="button" class="btn report-email-btn-tag" data-email="${email}">
                    ${email}
                    <svg class="embla-icon" aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <use xlink:href="/dist/icons/sprite.symbol.svg#remove"></use>
                    </svg>
                </button>
            </span>`);
            });
        } else {
            container.removeClass('margin-bottom-l');
        }
        this.updateAddButtonState();
    }

    private clearSelectedEmails() {
        this.selectedEmails = [];
        this.updateEmailTags();
        this.updateAddButtonState();
    }

    private async applyModal(reportId: string, reportName: string): Promise<void> {
        this.modal.showLoader();

        const selectedLanguage = $(this.emailLanguageSelectorSelector).val() as string;

        if (this.selectedEmails.length === 0) {
            this.showError(Localization.getText("ReportMailMinimum"));
            this.modal.hideLoader();
            return;
        }

        try {
            await this.garudaApi.requestSendReportMail(reportId, this.selectedEmails, selectedLanguage, reportName);

            NotificationModule.showSuccessSmall(Localization.getText("ReportMailSuccess"));

            this.clearSelectedEmails();
            this.modal.close();
        } catch (error) {
            // Do nothing here as error toast is shown
            // Does not close modal and does not clear email list
        } finally {
            this.modal.hideLoader();
        }
    }

    private clearAlertTimeout(): void {
        if (this.alertTimeout) {
            clearTimeout(this.alertTimeout);
            this.alertTimeout = null;
        }
    }

    private showError(message: string): void {
        const alertHtml = `<div class="alert alert-danger margin-top-l" role="alert">${message}</div>`;
        this.showAlert(alertHtml);
    }

    private showAlert(alertHtml: string): void {
        const modalBody = $(`${this.sendReportModalSelector} .modal-body`);

        modalBody.find('.alert').remove();

        modalBody.append(alertHtml);

        if (this.alertTimeout) {
            clearTimeout(this.alertTimeout);
        }

        this.alertTimeout = window.setTimeout(() => {
            modalBody.find('.alert').fadeOut('slow', function () {
                $(this).remove();
            });
            this.alertTimeout = null;
        }, 5000) as unknown as number;
    }

    private isValidEmail(email: string): boolean {
        // This regex validates email addresses with the following rules:
        // 1. Local part (before @):
        //    - Can contain any character except <>()[]\\.,;:\s@"
        //    - Can include dots, but not consecutively
        //    - Can be a quoted string (allowing special characters)
        // 2. Domain part (after @):
        //    - Can be an IP address in square brackets
        //    - Or a series of alphanumeric labels separated by dots
        //    - The last label must be at least 2 characters long
        const re = /^(([^<>()\[\]\\.,;:\s@""]+(\.[^<>()\[\]\\.,;:\s@""]+)*)|("".+""))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9øæåöäüØÆÅÖÄÜéÉèÈêÊëËçÇñÑß]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
    }
}
